<template>
    <div>
        <!--begin::Row-->
        <!-- <div class="row">
        <div class="col-lg-12"> -->
        <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header">
                <div class="card-title">
                    <h3 class="card-label">Masukkan Catatan</h3>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <!--begin::Item-->
                <div>
                    <!--begin::Content-->
                    <div>
                        <button type="button" class="btn btn-primary" size="sm"
                            style="background-color:#030A8C; border-color: #030A8C; margin-top:10px"
                            v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Tambah Catatan</button>
                        <br><br>
                        <div style="overflow-x:auto;">
                            <table id="example" class="table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nama Kementerian/Lembaga</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in catatan" :key="row.id">
                                        <td></td>
                                        <td>{{ row.nama_kl }}</td>
                                        <td>
                                            <button data-toggle="modal" data-target=".bd-example-modal-lg"
                                                @click="getcatatan(row.idkl)" style="margin:5px"
                                                class="btn btn-primary">Lihat Catatan <span v-if="row.punya_catatan" class="badge badge-danger">!</span></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Item-->
            </div>
            <!--end: Card Body-->
        </div>

        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Judul Kajian/Berita</th>
                                            <th>Tipe Kajian/Berita</th>
                                            <th>File</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in catatann" :key="row.id">
                                            <td></td>
                                            <td>{{ row.judul }}</td>
                                            <td>
                                                <div v-if="row.tipe === 'Negatif'" style="color:red">{{ row.tipe }}</div>
                                                <div v-else style="color:blue">{{ row.tipe }}</div>
                                            </td>
                                            <td>
                                                <div v-if="row.file != null">
                                                    <a :href="row.file" target="_blank"
                                                        class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                                                            class="fa fa-download" aria-hidden="true" /></a>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <button data-toggle="modal" data-target="#modal-edit"
                                                        @click="getPerCatatan(row.id)" style="margin:5px"
                                                        class="btn btn-success" v-b-tooltip.hover title="Ubah"><i
                                                            class="fa fa-edit"></i></button>
                                                    <button @click="deleteCatatan(row.id, row.idkl)" style="margin:5px"
                                                        class="btn btn-danger"><i class="fa fa-trash" v-b-tooltip.hover
                                                            title="Buang"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tambah -->
        <b-modal v-model="show" id="modal-tambah" ref="modal" size="xl" title="Tambah Catatan" @show="resetModal"
            @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="tambahcatatan">
                <div class="form-group row">

                    <label class="col-4">Kementerian/Lembaga</label>

                    <div class="col-8">
                        <b-form-select v-model="selectedprovinsi">
                            <option v-for="(dataprovinsi, k) in provinsi" :key="k" :value="dataprovinsi">
                                {{ dataprovinsi.nama_kl }}
                            </option>
                        </b-form-select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-4">Tipe</label>
                    <div class="col-8">
                        <b-form-select v-model="status_tipe" :options="tipe"></b-form-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                    <div class="col-lg-8">
                        <input disabled type="text" v-model="this.user.name" class="form-control" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                    <div class="col-lg-8">
                        <input type="text" v-model="judul" class="form-control" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Lembaga Asal/Sumber</label>
                    <div class="col-lg-8">
                        <input type="text" v-model="lembaga" class="form-control" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-xl-6">
                                <!--begin::Input-->
                                <div class="form-group">
                                    <b-form-datepicker v-model="tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                </div>
                                <!--end::Input-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::Input-->
                                <div class="form-group">
                                    <b-form-datepicker v-model="tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                </div>
                                <!--end::Input-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                    <div class="col-lg-8">
                        <vue-editor v-model="isi_berita" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                    <div class="col-lg-8">
                        <input type="text" v-model="sumber" class="form-control" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                    <div class="col-lg-8">
                        <b-form-file v-model="file" type="file" ref="file"></b-form-file>
                    </div>
                </div>
            </form>
            <template #modal-footer>
                <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button variant="primary" size="sm" class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white" @click="tambahCatatan()">
                        Tambah
                    </b-button>
                    <b-button variant="primary" size="sm" class="float-right" style="margin-right: 10px;"
                        @click="show = false">
                        Tutup
                    </b-button>
                </div>
            </template>
        </b-modal>


        <div class="modal fade" id="modal-edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Ubah Catatan</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form ref="form" @submit.stop.prevent="tambahcatatan">


                            <div class="form-group row">
                                <label class="col-4">Tipe</label>
                                <div class="col-8">
                                    <b-form-select v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                <div class="col-lg-8">
                                    <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getCatatan.judul" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Lembaga</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getCatatan.lembaga" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <!--begin::Input-->
                                            <div class="form-group">
                                                <b-form-datepicker v-model="getCatatan.tanggal_awal" locale="in"
                                                    class="mb-2"></b-form-datepicker>
                                            </div>
                                            <!--end::Input-->
                                        </div>
                                        <div class="col-xl-6">
                                            <!--begin::Input-->
                                            <div class="form-group">
                                                <b-form-datepicker v-model="getCatatan.tanggal_akhir" locale="in"
                                                    class="mb-2"></b-form-datepicker>
                                            </div>
                                            <!--end::Input-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                <div class="col-lg-8">
                                    <vue-editor v-model="getCatatan.deskripsi" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getCatatan.sumber" class="form-control" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                                <div class="col-lg-8">
                                    <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file>
                                    <div v-if="getCatatan.file == null">
                                    </div>
                                    <div v-else>
                                        <a :href="getCatatan.file" target="_blank"
                                            class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                                                class="fa fa-download" aria-hidden="true" /></a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button type="button" @click="editCatatan(getCatatan.id, getCatatan.idkl)"
                            style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal"
                            class="btn btn-primary">Simpan</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- </div>
  </div> -->
</template>
<style>
div.messages {
    /* background-color: lightblue; */
    /* width: 110px; */
    height: 500px;
    overflow: auto;
}
</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
import Button from '../vue-bootstrap/Button.vue';
import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Loading', // default 'Loading'
    loading: true, // default false
    //customLoader: myVueComponent, // replaces the spinner and text with your own
    background: 'rgb(255,255,255)', // set custom background
    classes: ['myclass'] // array, object or string
})

export default {
    name: "dashboard",
    components: { VueEditor },
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        // 15th two months prior
        //   const minDate = new Date('2020-01-01')

        // 15th in two months
        //   const maxDate = new Date('2021-07-31')
        return {
            url: localStorage.getItem('baseapi'),
            token: localStorage.getItem('id_token'),
            user: JSON.parse(localStorage.getItem('user')),
            show: false,
            test: 'hai',
            tahun: '',
            catatan: [],
            catatann: [],
            noCatatan: '',
            file: '',
            getCatatan: {
                id: '',
                tipe: '',
                penghimpun: '',
                judul: '',
                deskripsi: '',
                sumber: ''
            },
            search: '',
            searchcatatan: '',
            judul: '',
            sumber: '',
            lembaga: '',
            status_tipe: 'Negatif',
            tipe: [
                { value: 'Negatif', text: 'Negatif' },
                { value: 'Positif', text: 'Positif' },
            ],
            selectedprovinsi: [{
                idkl: '',
                nama_kl: ''
            }],
            provinsi: [],
            tanggal_awal: '',
            tanggal_akhir: '',
            isi_berita: "",
            getkegiatan: [],
            json_fields: {
                Agenda: "agenda",
                Tahun: "tahun",

            },
            // min: minDate,
            // max: maxDate
        }
    },
    //   components: {
    //       VueEditorButton
    //   },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "PPB Kementerian/Lembaga" }]);
        this.tahun = new Date().getFullYear();
        this.loaddata();
    },
    methods: {
        loaddata() {
            axios.get(this.url + '/rekapitulasi_kl?tahun=2024', {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            })
                .then(response => {
                    this.provinsi = response.data;
                    this.catatan = response.data;
                    this.initDatatable()
                }).catch(error => {
                    return error
                });
        },
        getcatatan(idkl) {
            $('#example2').DataTable().destroy();
            this.catatann = null;
            this.$loading(true);
            axios.get(this.url + "/catatan_ta_kl?filter=idkl,=," + idkl + ";tahun,=," + this.tahun, {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            })
                .then(response => {
                    this.$loading(false);
                    var catatannomine = response.data.data
                    this.catatann = catatannomine;
                    this.initDatatable2()
                }).catch(error => {
                    this.$loading(false);
                    return error
                    // this.loaddata()
                });
        },
        initDatatable() {
            setTimeout(() => {
                var t = $('#example').DataTable({
                    "pagingType": "full_numbers",
                    "language": {
                        "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                        "paginate": {
                            "first": "Awal",
                            "last": "Akhir",
                            "next": "Selanjutnya",
                            "previous": "Sebelumnya"
                        },
                    },
                    "columnDefs": [
                        { "width": "5px", "targets": 0 }
                    ],
                    "lengthMenu": [
                        [10, 25, 50, -1],
                        [10, 25, 50, "All"]
                    ],
                    // order: [[7, 'asc']],
                    responsive: true,
                    destroy: true,
                    retrieve: true,
                    autoFill: true,
                    colReorder: true,

                });
                t.on('order.dt search.dt', function () {
                    t.column(0, { search: 'applied', order: 'applied' }).nodes().each(function (cell, i) {
                        cell.innerHTML = i + 1;
                    });
                    // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                    //     cell.innerHTML = i+1;
                    // } );
                }).draw();
            }, 300)
        },
        initDatatable2() {
            setTimeout(() => {
                var t = $('#example2').DataTable({
                    "pagingType": "full_numbers",
                    "language": {
                        "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                        "paginate": {
                            "first": "Awal",
                            "last": "Akhir",
                            "next": "Selanjutnya",
                            "previous": "Sebelumnya"
                        },
                    },
                    "lengthMenu": [
                        [10, 25, 50, -1],
                        [10, 25, 50, "All"]
                    ],
                    // order: [[7, 'asc']],
                    responsive: true,
                    destroy: true,
                    retrieve: true,
                    autoFill: true,
                    colReorder: true,

                });
                t.on('order.dt search.dt', function () {
                    t.column(0, { search: 'applied', order: 'applied' }).nodes().each(function (cell, i) {
                        cell.innerHTML = i + 1;
                    });
                    // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                    //     cell.innerHTML = i+1;
                    // } );
                }).draw();
            }, 300)
        },
        resetModal() {
            this.tanggal_awal = ''
            this.tanggal_akhir = ''
            this.status_tipe = 'Negatif'
            this.file = null
            this.judul = ''
            this.isi_berita = ''
            this.sumber = ''
            this.lembaga = ''
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.tambahkegiatan()
        },
        tambahCatatan() {
            this.$loading(true);
            // Exit when the form isn't valid
            // if (!this.checkFormValidity()) {
            //   return
            // }
            let formData = new FormData();
            formData.append('idkl', this.selectedprovinsi.idkl);
            formData.append('nama', this.selectedprovinsi.nama_kl);
            formData.append('tahun', this.tahun);
            formData.append('tipe', this.status_tipe);
            formData.append('penghimpun', this.user.name);
            formData.append('judul', this.judul);
            formData.append('deskripsi', this.isi_berita);
            formData.append('sumber', this.sumber);
            formData.append('tanggal_awal', this.tanggal_awal);
            formData.append('tanggal_akhir', this.tanggal_akhir);
            formData.append('lembaga', this.lembaga);
            formData.append('file', this.file);
            axios
                .post(this.url + '/catatan_ta_kl', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'xth': this.token
                    }
                })
                .then(response => {
                    this.$loading(false);
                    this.$bvToast.toast('Berhasil Disimpan', {
                        title: `Success `,
                        variant: `success`,
                        solid: true
                    })
                    console.log(response);
                    this.loaddata()
                    return response

                })
                .catch(error => {
                    this.$loading(false);
                    console.log(error)
                    this.$bvToast.toast(error.response.data.message, {
                        title: `Failed `,
                        variant: `danger`,
                        solid: true
                    })
                    return error
                });
            // Push the name to submitted names
            // this.submittedNames.push(this.name)
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-tambah')
            })
        },
        getPerCatatan(id) {
            axios.get(this.url + "/catatan_ta_kl?filter=id,=," + id, {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            }).then(response => {
                this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idkl = response.data.data[0].idkl;
                this.getCatatan.file = response.data.data[0].file;
                console.log(response.data.data)
            });
        },
        handleEdit(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.editsdm()
        },
        editCatatan(id, idkl) {
            this.$loading(true);
            let formData = new FormData();
            formData.append('tipe', this.getCatatan.tipe,);
            formData.append('penghimpun', this.getCatatan.penghimpun,);
            formData.append('judul', this.getCatatan.judul);
            formData.append('deskripsi', this.getCatatan.deskripsi);
            formData.append('sumber', this.getCatatan.sumber);
            formData.append('tanggal_awal', this.getCatatan.tanggal_awal);
            formData.append('tanggal_akhir', this.getCatatan.tanggal_akhir);
            formData.append('lembaga', this.getCatatan.lembaga);
            formData.append('file', this.getCatatan.file);
            axios
                .post(this.url + `/catatan_ta_kl/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'xth': this.token
                    }
                })
                .then(response => {
                    this.$loading(false);
                    this.$bvToast.toast('Berhasil Diedit', {
                        title: `Success `,
                        variant: `success`,
                        solid: true
                    })
                    console.log(response);
                    // this.getCatatan.file = null;
                    this.getcatatan(idkl)
                    return response

                })
                .catch(error => {
                    this.$loading(false);
                    console.log(err)
                    this.$bvToast.toast(error.response.data.message, {
                        title: `Failed `,
                        variant: `danger`,
                        solid: true
                    })
                    return error
                });
            // Push the name to submitted names
            // this.submittedNames.push(this.name)
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
        deleteCatatan(id, idkl) {
            axios.delete(this.url + `/catatan_ta_kl/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            })
                .then((res) => {
                    this.$bvToast.toast('Berhasil Dihapus', {
                        title: `Success `,
                        variant: `success`,
                        solid: true
                    })
                    this.getcatatan(idkl)
                    return res;
                })
                .catch((err) => {
                    console.log(err)
                    this.$bvToast.toast(error.response.data.message, {
                        title: `Failed `,
                        variant: `danger`,
                        solid: true
                    })
                    return err;
                });
        },
        setActiveTab1(event) {
            this.tabIndex = this.setActiveTab(event);
        },
        setActiveTab2(event) {
            this.tabIndex2 = this.setActiveTab(event);
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        }
    },
    created() {
        //   this.getuser(this.user)
        // this.getlocaldata()
    }
};
</script>

